import React from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import Web3 from 'web3'

import TokenVestingApp from './views/TokenVestingApp'

const App = () => (
  <Router>
    <Switch>
      <Route path="/:address/:token" component={ Main }/>
      <Route component={ NavigateList } />
    </Switch>
  </Router>
)

const Main = function({ match }) {
  let web3 = new Web3()
  let { address, token } = match.params

  // TODO validate TokenVesting address
  return web3.utils.isAddress(address)
    ? <TokenVestingApp address={ address } token={ token } />
    : <NavigateList />
}

const NavigateList = () => (
  <div>
    <h1>Vesting List</h1>
    <hr/>
    <a href="/#/0x3CB2F8F2312ab981C4da6f98735A2AF79e781a3f/0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830">for beneficiary is 0x3CB2F8F2312ab981C4da6f98735A2AF79e781a3f</a>
    <br/>
    <a href="/#/0x6AEc9e1a535060A13D6a3a9F62F773333C0B4fC4/0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830">for beneficiary is 0x6AEc9e1a535060A13D6a3a9F62F773333C0B4fC4</a>
    <br/><br/>
    <span>You should open metamask in you browser already.</span>
  </div>
  
)

export default App