import Web3 from 'web3'
import { sleep } from './utils'

const Network = {
  async web3() {
    const provider = await Network.provider()
    return new Web3(provider)
  },

  // async eth() {
  //   const web3 = await Network.web3()
  //   return web3.eth
  // },

  async provider() {
    let { ethereum } = window

    while (ethereum === undefined) {
      Network.log("Waiting for web3")
      await sleep(500)
      ethereum = window.ethereum
    }

    return ethereum;
  },

  async getAccounts() {
    return await window.ethereum.request({ method: 'eth_requestAccounts' })
  },

  // _web3Callback(resolve, reject) {
  //   return (error, value) => {
  //     if (error) reject(error)
  //     else resolve(value)
  //   }
  // },

  log(msg) {
    console.log(`[Network] ${msg}`)
  }
}

export default Network
